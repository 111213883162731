<template lang="pug">
#DeliveryDriverDetail
  b-card.delivery-card(v-if="delivery && delivery.address && !editDelivery")
    .d-flex.flex-row.text-secondary.mb-2
      div {{ delivery.store ? delivery.store.name : 'No encontrado' }}
      span(style="font-weight:bold; color:#00C5AC" @click="SET_EDIT_DELIVERY(true)").ml-auto.mr-2 EDITAR
    h5 {{ delivery.address.street }}
    h5 {{ delivery.address.commune }}
    div.text-light-primary {{ delivery.customerName }}
    .contacto
      div.text-light-secondary(style="font-weight:bold") {{ delivery.customerPhone }}
      .botones-contacto
        a(:href="`tel:+56${delivery.customerPhone}`")
          .material-icons.text-light-secondary.icon phone
        font-awesome-icon.text-light-secondary.icon(:icon="['fab', 'whatsapp']" @click="abrirWhatsapp")

    b-form
      .box-form
        .material-icons.icon-input store
        b-form-group(
          label-for="store-input"
        )
          .input-title Estado del Envío
          b-form-select#state-input.input(
            v-model="state"
            :options="stateOptions"
            v-if="delivery.state === null"
          )
          div.text(v-else style="text-transform: capitalize;") {{ delivery.state }}
      hr.linea
      .box-form(v-if="state")
        .material-icons.icon-input assignment
        b-form-group(
          label-for="store-input"
        )
          .input-title Comentarios
          b-form-textarea#commentary-input.input(
            v-model="form.commentary"
            rows="1"
            max-rows="3"
            no-auto-shrink
            :state="!!form.commentary == true ? null : false"
            placeholder="Ingresa Comentarios"
          )
          b-form-invalid-feedback(:state="!!form.commentary") El comentario es obligatorio
      .box-form(v-if="delivery.state != null && delivery.commentary != ''")
        .material-icons.icon-input assignment
        b-form-group(
          label-for="store-input"
        )
          .input-title Comentarios
          div.text {{ delivery.commentary }}
      hr.linea(v-if="state")

      div(v-if="delivery.images.length > 0")
        div
          div(v-for="image in delivery.images")
            b-img(:src="`${image.url}`" fluid)

      .box-form(v-if="state != null")
        .material-icons.icon-input file_upload
        b-form-group(
          label-for="store-input"
        )
          .input-title Subir Imagenes (3 máximo)
          b-form-file#input-file.input(
            ref="input-file"
            placeholder="Selecciona Archivos"
            browse-text="Buscar"
            accept = "image/jpeg,image/png,image/gif"
            multiple
            variant ="secondary"
            v-model="images"
          )
      hr.linea

    b-button.boton(v-if="delivery.state === null" type="submit" @click="onSubmit" style="float: right;" :disabled="loading") Guardar
  DeliveryDriverEdit(v-show="delivery && delivery.address && editDelivery")
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MakeToastMixin from '../mixins/MakeToastMixin'
import DeliveryDriverEdit from './DeliveryDriverEdit'

export default {
  mixins: [MakeToastMixin],
  components: {
    DeliveryDriverEdit
  },
  data () {
    return {
      state: null,
      loading: false,
      images: [],
      form: {
        state: null,
        commentary: '',
        driver: this.delivery ? this.delivery.driver : null,
        driverId: this.delivery ? this.delivery.driverId : null,
        date: ''
      },
      stateOptions: [
        { value: null, text: 'Selecciona' },
        { value: 'entregado', text: 'Entregado' },
        { value: 'pendiente', text: 'Pendiente' },
        { value: 'rechazado', text: 'Rechazado' }
      ]
    }
  },
  methods: {
    ...mapActions(['getDelivery', 'updateDelivery', 'createDeliveryImage']),
    ...mapMutations(['SET_EDIT_DELIVERY']),
    abrirWhatsapp () {
      let formatedPhone = this.delivery.customerPhone
      if (!formatedPhone) return
      formatedPhone = formatedPhone.trim()
      formatedPhone = formatedPhone.replace('+', '')
      formatedPhone = formatedPhone.slice(-8)
      window.open(`https://api.whatsapp.com/send?phone=569${formatedPhone}`, '_blank')
    },
    validate () {
      this.form.commentary = this.form.commentary?.trim()
      return !!this.form.commentary
    },
    async onSubmit () {
      if (!this.validate()) {
        return
      }
      this.loading = true
      this.form.date = this.delivery.date
      this.form.state = this.state
      this.form.driverId = this.delivery.driverId
      this.form.lastUpdateState = new Date()
      const data = { ...this.form }
      const responseDelivery = await this.updateDelivery({ id: this.delivery.id, data })
      if (responseDelivery.status === 200) {
        await this.loadImages(this.images)
        this.makeSuccessToast('Estado del envío actualizado')
        this.$emit('done')
        location.reload()
      } else if (responseDelivery.status >= 400) {
        this.makeDangerToast(responseDelivery.data.message || 'Error al editar el envío')
      }
      this.loading = false
    },
    async loadImages (files) {
      if (!files || files.length === 0) return
      const data = new FormData()
      for (const file of files) {
        data.append('files', file)
      }
      await this.createDeliveryImage({ data, deliveryId: this.delivery.id })
    }
  },
  async created () {
    await this.getDelivery({ id: this.$route.params.id })
  },
  computed: {
    ...mapGetters(['delivery', 'editDelivery'])
  },
  watch: {
    images () {
      if (this.images.length > 3) {
        this.images = this.images.slice(0, 3)
      }
    }
  }
}
</script>
<style lang="scss">
.contacto {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 15px;
}
.icon {
  margin:2px;
  cursor: pointer;
  font-size: 26px;
}
.botones-contacto {
  display: flex;
  width: 70px;
  justify-content: space-between;
  align-items: baseline;
}
.text{
    margin-left: 11px;
    margin-bottom: 10px;
}
</style>
